<!--
File: TrafficEditFrom.vue
Description: form for adding/editing a single traffic data.
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout md-size-50">
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field :class="[{ 'md-valid': !errors.has('description') }, { 'md-error': errors.has('description') }]">
              <label for="description">{{ $t('stdCols.description') }}</label>
              <md-input id="description" v-model="description" type="text" data-vv-name="description" required
                v-validate="modelValidations.description"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field class="md-field">
              <label for="traffic_site_id">{{ $t('traffic.site_id') }}</label>
              <md-input id="traffic_site_id" v-model="traffic_site_id" type="number" data-vv-name="traffic_site_id"
                required></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field class="md-field">
              <label for="photo_filename">{{ $t('traffic.photo_filename') }}</label>
              <md-input id="photo_filename" v-model="photo_filename" type="text" data-vv-name="photo_filename"></md-input>
            </md-field>
          </div>
        </div>
        <div class="md-layout-item md-size-100 text-right">
          <md-button class="md-success" native-type="submit" @click.native.prevent="validate" :disabled="saveBtnDisabled">
            {{ $t('buttons.save') }}
          </md-button>
          <md-button class="md-accent" @click.stop.prevent="onClose">
            {{ $t('buttons.close') }}
          </md-button>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import messagesMixin from '@/mixins/messagesMixin'
  import { onClose } from '@/mixins/onCloseMixin'

  export default {
    name: 'traffic-edit-form',
    mixins: [messagesMixin],

    data() {
      return {
        tid: null,
        description: null,
        traffic_site_id: null,
        photo_filename: null,

        modelValidations: {
          description: { required: true, min: 3 },
          traffic_site_id: { required: true },           //, numeric: true         
          photo_filename: { min: 3 }
        }
      }
    },

    props: {
      oper: String
    },

    components: {
    },

    mounted() {
      const { tid = null } = this.$route.params
      this.tid = tid
      if (this.oper === 'upd' && this.tid) {
        this.$store.dispatch('LOAD_TRAFFIC_BY_ID', this.tid).then((res) => {
          const traffic = Array.isArray(res) ? res[0] : res
          this.description = traffic.description
          this.traffic_site_id = traffic.traffic_site_id
          this.photo_filename = traffic.photo_filename
        })
      }
      this.$store.dispatch('LOAD_TRAFFIC_INSTALLATION').then(() => {
        //      const defUnit = this.unitsList[0].id
        //      console.log(this.units)
      })
      this.$nextTick(() => {
        this.$validator.validateAll()
      })
    },

    methods: {
      onClose,

      async validate() {
        const isValid = await this.$validator.validateAll();
        if (!isValid) return

        const theTraffic = {
          description: this.description,
          traffic_site_id: this.traffic_site_id,
          photo_filename: this.photo_filename
        }

        const payload = this.oper === 'add' ? theTraffic : { id: this.tid, theTraffic }
        const action = `${this.oper.toUpperCase()}_TRAFFIC_INSTALLATION`;
        let errDesc = ''
        try {
          await this.$store.dispatch(action, payload);
        } catch (err) {
          errDesc = err.message
        }
        this.savedMessage(errDesc, this.$t('route.traffic_data'), theTraffic.description)

        this.$nextTick(() => {
          this.$validator.reset()
        })
        this.onClose();
      },
    },

    computed: {
      saveBtnDisabled() {
        return this.errors.count() > 0
      }
    },

    watch: {
    },
  }
</script>
<style>
.md-button+.md-button {
  margin-left: 10px;
}
</style>
